import { Box, Stack } from '@mui/joy'

const Footer = () => {
    return (
        <Box sx={{ margin: 'auto', width: '100%', backgroundColor: 'green', color: 'white' }}>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Box>
                    <h4>www.flsa.com.au</h4>
                </Box>
            </Stack>
        </Box >
    )
}

export default Footer