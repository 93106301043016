import './App.css';
import ProjectForm from './components/ProjectForm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Footer from './components/Footer';
import Header from './components/Header';

function App() {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Header />
        <ProjectForm />
        <Footer />
      </LocalizationProvider>
    </>
  );
}

export default App;
