import { Box, Button, Input, Select, Stack } from '@mui/joy'
import React from 'react'
import logo from '../assests/flsa.png'
import { DatePicker } from '@mui/x-date-pickers'
import Option from '@mui/joy/Option';

interface project {

}

const ProjectForm = () => {
    return (
        <Box component="section" sx={{ p: 2, minHeight: '100vh', marginBottom: '5vh' }}>
            <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
            >
                <img src={logo} width={200} />
                <Box sx={{}}>
                    <h1>PROJECT FORM</h1>
                    <p>
                        Please complete the below form and our team will get in touch with you.
                    </p>
                    <div>
                        <h3>Customer Details</h3>
                    </div>
                    <div>
                        <p>Your Business Name</p>
                        <Input />
                        <p>Your Email</p>
                        <Input />
                    </div>
                    <div>
                        <h3>Pickup Details</h3>
                    </div>
                    <div>
                        <p>Requested Despatch Date</p>
                        <DatePicker />
                    </div>
                    <div>
                        <p>Pickup Business Name</p>
                        <Input />
                        <p>Pickup Contact Name</p>
                        <Input />
                        <p>Pickup Contact Number</p>
                        <Input />
                        <p>Pickup Address</p>
                        <Input />
                        <p>Pickup Suburb / State / Postcode</p>
                        <Input />
                        <p>Special Instructions</p>
                        <Input />
                    </div>
                    <div>
                        <h3>Delivery Details</h3>
                    </div>
                    <div>
                        <p>Receiver Business Name</p>
                        <Input />
                        <p>Receiver Contact Name</p>
                        <Input />
                        <p>Receiver Contact Number</p>
                        <Input />
                        <p>Receiver Address</p>
                        <Input />
                        <p>Receiver Suburb / State / Postcode</p>
                        <Input />
                        <p>Special Instructions</p>
                        <Input />
                    </div>
                    <div>
                        <h3>Freight Details</h3>
                    </div>
                    <Box>
                        <Select placeholder="Service (Choose)">
                            <Option value='B Double - Mezz Floor'>B Double - Mezz Floor</Option>
                            <Option value='B Double - Taut'>B Double - Taut</Option>
                            <Option value='B Double - Tray'>B Double - Tray</Option>
                            <Option value='B Double - Tray'>B Double - Tray</Option>
                            <Option value='Half Load'>Half Load</Option>
                            <Option value='Hot Shot'>Hot Shot</Option>
                            <Option value='Semi Mezz Floor'>Semi Mezz Floor</Option>
                            <Option value='Semi / Taut'>Semi / Taut</Option>
                            <Option value='Semi / Tray'>Semi / Tray</Option>
                            <Option value='20 Foot Container'>20 Foot Container</Option>
                            <Option value='40 Foot Container'>40 Foot Container</Option>
                            <Option value='Tilt Tray'>Tilt Tray</Option>
                            <Option value='Moffett'>Moffett</Option>
                        </Select>
                    </Box>
                    <Box sx={{ marginTop: '2vh' }}>
                        <Select placeholder="Loading Method (Choose)">
                            <Option value='Forklift'>Forklift</Option>
                            <Option value='Franna'>Franna</Option>
                            <Option value='Gantry Crane'>Gantry Crane</Option>
                            <Option value='Loading Dock'>Loading Dock</Option>
                            <Option value='Loading Method'>Loading Method</Option>
                            <Option value='Moffett'>Moffet</Option>
                        </Select>
                    </Box>
                    <Box sx={{ marginTop: '2vh' }}>
                        <Select placeholder="Unloading Method (Choose)">
                            <Option value='Forklift'>Forklift</Option>
                            <Option value='Franna'>Franna</Option>
                            <Option value='Gantry Crane'>Gantry Crane</Option>
                            <Option value='Loading Dock'>Loading Dock</Option>
                            <Option value='Loading Method'>Loading Method</Option>
                            <Option value='Moffett'>Moffet</Option>
                        </Select>
                    </Box>
                    <Box>
                        <p>Job Details</p>
                        <Input placeholder='5 x pallet 3 tonne etc' />
                    </Box>
                    <Box sx={{ marginTop: '2vh' }}>
                        <Button color="success">Submit</Button>
                    </Box>
                </Box>
            </Stack >
        </Box >
    )
}

export default ProjectForm